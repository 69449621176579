@charset 'UTF-8';

// breakpoints
//
$breakpoints: (
  'sm' : 560,
  'md' : 760,
  'lg' : 980
) !default;



// fluid [ true or false ]
//
$fluid_sm: true;
$fluid_md: true;
$fluid_lg: true;



// .container の横幅
//
$container_width_array: (
  'sm': 100%,
  'md': 100%,
  'lg': 1164px
) !default;



// fluid が false の時の min-width を指定する
// $container_width_array と同じ場合は同じ値を指定する
//
$container_min_width_array: (
  'sm': 100%,
  'md': 100%,
  'lg': 1164px
) !default;



// .container-wrapper の左右の padding
//
$container_padding_array: (
  'sm': 24px,
  'md': 32px,
  'lg': 36px
) !default;



// .container-wrapper の background-color
//
$background_container_wrapper: transparent !default;



// .container の background-color
//
$background_container: transparent !default;



// .section-inner-upper .section-inner .section-inner-lower 上下の余白（単位：em）
//
$section_gutter_array: (
  'sm': 2em,
  'md': 2em,
  'lg': 3em
) !default;



// .element 上下の余白（単位：em）
//
$element_gutter_array: (
  'sm': 1em,
  'md': 1em,
  'lg': 1em
) !default;



// z-index
//
$z_index_array: (
  'header'    : 3,
  'main'      : 0,
  'footer'    : 1,
  'copyright' : 1
) !default;
