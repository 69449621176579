@charset 'UTF-8';

// border の太さ
//
$block_border: 4px;



// border-radius
//
$block_border_radius: 8px;



// block の box-shadow
//
$block_box_shadow: 0 2px 4px 0 rgba( 0,0,0, .12 );



// background-color, color ( block-header )
//
$block_colors_array: (
  'base'   : ( 'bg' : $base_color,   'color' : $white-color ),
  'accent'  : ( 'bg' : $accent_color,  'color' : $white-color ),
  'schedule': ( 'bg': $schedule-color, 'color': $white-color ),
  'closure' : ( 'bg': $closure-color,  'color': $white-color ),
  'section' : ( 'bg': $section-color,  'color': $white-color ),
  'access'  : ( 'bg': $access-color,   'color': $white-color )
) !default;
