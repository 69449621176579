@charset 'UTF-8';

// .header-container の横幅
// .container と合わせたい場合は $container_width_array を使用する
//
$header_container_width_array: (
  'sm': map-get( $container_width_array, sm ),
  'md': map-get( $container_width_array, md ),
  'lg': map-get( $container_width_array, lg )
) !default;



$header_container_min_width_array: (
  'sm': map-get( $container_min_width_array, sm ),
  'md': map-get( $container_min_width_array, md ),
  'lg': map-get( $container_min_width_array, lg )
) !default;



// .header-container の左右の padding
// .container と合わせたい場合は $container_padding_array を使用する
//
$header_container_padding_array: (
  'sm': map-get( $container_padding_array, sm ),
  'md': map-get( $container_padding_array, md ),
  'lg': map-get( $container_padding_array, lg )
) !default;



// header の高さ
//
$header_height_array: (
  sm: 6rem,
  md: 6rem,
  lg: 12.6rem
  ) !default;



// main と header の間隔
//
$header_padding_bottom_array: (
  sm: 0,
  md: 0,
  lg: 0
) !default;



// header の background-color
//
$background_header: rgba( 255, 255, 255, .95 ) !default;
