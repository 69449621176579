@charset 'UTF-8';

// $font_size_array 配列から key を取り出してリスト化
//
$font_size_key_array: map-keys( $font_size_array );



// 文字サイズによる padding
//
// リストの順番 'exlarge', 'xxlarge', 'xlarge', 'large', 'medium', 'small', 'xsmall'
$button_size_padding_vertical_value_array:   ( .5em, .5em, .5em, .5em, .6em, .6em, .6em ); // 上下
$button_size_padding_horizontal_value_array: ( .5em, .5em, .8em, 1em, 1em, 1em, .7em ); // 左右

// map-zip( $keys, $values ) キーのリスト、値のリストの二つから新しい map を生成して返す
//
$button_size_padding_vertical_array:   map_zip( $font_size_key_array, $button_size_padding_vertical_value_array );
$button_size_padding_horizontal_array: map_zip( $font_size_key_array, $button_size_padding_horizontal_value_array );



// button 上下の padding
//
$button_padding_vertical_array: (
  sm: .7em,
  md: .7em,
  lg: .7em
) !default;



// button 左右の padding
//
$button_padding_horizontal_array: (
  sm: 1em,
  md: 1em,
  lg: 1.25em
) !default;



// 角丸
//
$button_border_radius: 4px !default;



// アウトライン時のボーダーの太さ
$button_border: 3px !default;



// button color
//

// background-color, color
$button_color_array: (
  'base'    : ( 'bg': $base-color,     'color': $white-color ),
  'accent'   : ( 'bg': $accent-color,    'color': $white-color ),
  'gray'  : ( 'bg': $gray-600,   'color': $white-color ),
  'schedule': ( 'bg': $schedule-color, 'color': $white-color ),
  'closure' : ( 'bg': $closure-color,  'color': $white-color ),
  'section' : ( 'bg': $section-color,  'color': $white-color ),
  'access'  : ( 'bg': $access-color,   'color': $white-color )
) !default;

$button_hover_color_array: (
  'base'    : ( 'bg': darken($base-color, 10%),     'color': $white-color ),
  'accent'   : ( 'bg': darken($accent-color, 10%),    'color': $white-color ),
  'gray'  : ( 'bg': $gray-700,   'color': $white-color ),
  'schedule': ( 'bg': darken($schedule-color, 10%), 'color': $white-color ),
  'closure' : ( 'bg': darken($closure-color, 10%),  'color': $white-color ),
  'section' : ( 'bg': darken($section-color, 10%),  'color': $white-color ),
  'access'  : ( 'bg': darken($access-color, 10%),   'color': $white-color )
) !default;

// outline
//

// background-color, color, border-color
$button-outline-color-array: (
  'base'    : ( 'bg': $white-color, 'color': $base-color,     'border': $base-color ),
  'accent'   : ( 'bg': $white-color, 'color': $accent-color,    'border': $accent-color ),
  'gray'   : ( 'bg': $white-color, 'color': $gray-600,    'border': $gray-700 ),
  'schedule': ( 'bg': $white-color, 'color': $schedule-color, 'border': $schedule-color ),
  'closure' : ( 'bg': $white-color, 'color': $closure-color,  'border': $closure-color ),
  'section' : ( 'bg': $white-color, 'color': $section-color,  'border': $section-color ),
  'access'  : ( 'bg': $white-color, 'color': $access-color,   'border': $access-color )
) !default;

$button-outline-hover-color-array: (
  'base'    : ( 'bg': $base-color,     'color': $white-color, 'border': $base-color ),
  'accent'   : ( 'bg': $accent-color,    'color': $white-color, 'border': $accent-color ),
  'gray'   : ( 'bg': $gray-700, 'color': $white-color,    'border': $gray-700 ),
  'schedule': ( 'bg': $schedule-color, 'color': $white-color, 'border': $schedule-color ),
  'closure' : ( 'bg': $closure-color,  'color': $white-color, 'border': $closure-color ),
  'section' : ( 'bg': $section-color,  'color': $white-color, 'border': $section-color ),
  'access'  : ( 'bg': $access-color,   'color': $white-color, 'border': $access-color )
) !default;
