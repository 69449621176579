@charset 'UTF-8';


// TOP PAGE WRAPPER
//
.top-topix-article-wrapper {
  background: $common-bg-color;
  padding: 16px 0;
}

.topix-article-container {
  display: flex;
  flex-flow: row wrap;
  margin: -8px;
  @include media(lg){
    margin: -10px;
  }
}

.topix-article-item {
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  padding: 8px;
  @include media(lg){
    flex: 0 0 percentage( 1/3 );
    width: percentage( 1/3 );
    max-width: percentage( 1/3 );
    padding: 10px;
  }
  a {
    text-decoration: none;
  }
}



// CARD
//
.topix-article-card {
  overflow: hidden;
  position: relative;
  height: 140px;
  @include media(lg){
    height: 240px;
  }
  &:hover {
    .article-images {
      transform: scale( 1.125 );
    }
  }
}

.article-images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: transform $transition;
}

.article-text {
  background: rgba( $white-color, .8 );
  color: $body-text-color;
  display: block;
  font-size: 12px;
  padding: 12px 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  @include media(md){
    font-size: 14px;
  }
  // 左寄せ
  &._left {
    text-align: left;
    padding: 12px 12px;
  }
}
