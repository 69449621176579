@charset 'UTF-8';

// heading initialize
//
h1, h2, h3, h4, h5, h6 {
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: .4em;
  line-height: 1.4;
}



// heading
//
h1 {
  font-size: $h1-font-size;
  &.default {
    font-family: '游明朝', YuMincho, 'Hiragino Mincho ProN W3', 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
    font-size: 24px;
    line-height: 1.2;
    margin: 0;
    @include media(lg){
      font-size: 36px;
    }
    .sub {
      color: $gray-700;
      display: block;
      font-family: $font-family;
      font-size: 14px;
      margin-top: 8px;
      @include media(lg){
        font-size: 20px;
        margin-top: 12px;
      }
    }
  }
}

h2 {
  font-size: $h2-font-size;
  &.default {
    background: #f1f3f5;
    border-left: 3px solid $base-color;
    color: #555;
    font-size: 20px;
    margin-bottom: 44px;
    padding: 14px 0 12px 16px;
    @include media(lg){
      border-left: 4px solid $base-color;
      font-size: 28px;
      margin-bottom: 54px;
      padding: 14px 0 14px 24px;
    }
  }
  &.closure-section {
    background: $base-color;
    color: $white-color;
    font-size: 16px;
    padding: 12px 0px 12px 16px;
  }
}

h3 {
  font-size: $h3-font-size;
  &.default {
    color: $base-color;
    border-bottom: 1px dashed $gray-500;
    font-size: 18px;
    margin-bottom: 24px;
    padding-bottom: 8px;
    @include media(lg){
      font-size: 24px;
      margin-bottom: 32px;
    }
  }
}

h4 {
  font-size: $h4-font-size;
  &.default {
    border-bottom: 4px solid lighten( $base-color, 20% );
    color: $base_color;
    font-size: 16px;
    margin-bottom: .8em;
    padding-bottom: .25em;
    text-align: center;
    @include media(lg){
      font-size: 20px;
    }
  }
}

h5 {
  font-size: $h5-font-size;
  margin-bottom: .5em;
}

h6 {
  font-size: $h6-font-size;
  margin-bottom: .5em;
}



// paragraph
//
p {
  font-size: inherit;
  letter-spacing: map-get( $p_letter_spacing_array, sm );
  line-height: map-get( $p_line_height_array, sm );
  text-rendering: optimizeLegibility;
  @include media(md) {
    letter-spacing: map-get( $p_letter_spacing_array, md );
    line-height: map-get( $p_line_height_array, md );
  }
  @include media(lg) {
    letter-spacing: map-get( $p_letter_spacing_array, lg );
    line-height: map-get( $p_line_height_array, lg );
  }
}



// フォントファミリーを日本語に限定する
//
.kome {
  font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Sans', 'Original Yu Gothic', 'Yu Gothic', sans-serif !important;
}



// 主に paragraph を段落として使用しない場合に margin-bottom を打ち消す
//
.phrase {
  margin-bottom: 0 !important;
}

.weight-normal {
  font-weight: normal !important;
}


// イタリック
//
i {
  font-style: italic;
  line-height: inherit;
}



// ボールド
//
strong,
em,
b {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}



// code
//
pre {
  background: $gray-100;
  margin-top: 1em;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
}

code {
  background: transparent;
  color: $gray-700;
  font-family: 'SourceHanCodeJP-Regular', monospace;
  font-size: .8em;
  font-weight: bold;
  vertical-align: initial;
}



// マーカーテキスト
//
$text-marker-weight: 65%;
.text-marker {
  background: linear-gradient( transparent $text-marker-weight, #fff799 $text-marker-weight );
  display: inline;
  font-weight: 700;
  padding: 0 .25em;
  // Color Pattern theme color
  @each $name, $value in $color_pattern_array {
    &.#{$name} {
      background: linear-gradient( transparent $text-marker-weight, lighten($value, 20%) $text-marker-weight );
    }
  }
}
