@charset 'UTF-8';

// 下層ページ
// 2カラム用サイドナビ


// サイドナビ
ul.local-sidebar-nav {
  background: $gray-100;
  display: flex;
  flex-flow: row wrap;
  @include media(lg){
    display: block;
  }
  // list
  > li {
    border-bottom: 1px dashed $gray-300;
    flex: 0 0 100%;
    @include media(md){
      flex: 0 0 50%;
    }
    > a {
      color: $body-text-color;
      display: block;
      padding: 16px 24px;
      position: relative;
      text-decoration: none;
      transition: color $transition;
      &:hover {
        color: $base-color;
      }
      &:after {
        content: '\f105';
        font-family: $icon_font_family;
        color: $base-color;
        display: inline-block;
        font-weight: 900;
        text-align: center;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
      }
      @include media(lg){
        font-size: 14px;
      }
      // 外部リンク
      &.outside {
        &:after {
          content: '\f35d';
        }
      }
    }
    &.category {
      border: $button_border solid $body-text-color;
      flex: 0 0 100%;
      padding: 2px;
      a {
        font-size: 16px;
        padding: 0;
        &:after {
          right: 10px;
          @include media(md){
            right: 12px;
          }
          @include media(lg){
            right: 12px;
          }
        }
      }
      span {
        border: 1px solid $body-text-color;
        display: block;
        padding: 18px 18px;
        @include media(lg){
          padding: 36px 18px;
        }
      }
    }
  }
}

// 子メニュー
.local-sidebar-child-nav {
  li {
    a {
      color: $body-text-color;
      display: block;
      font-size: 13px;
      padding: 12px 24px 12px 38px;
      position: relative;
      text-decoration: none;
      transition: color $transition;
      &:hover {
        color: $base-color;
      }
      &:after {
        content: '\f105';
        font-family: $icon_font_family;
        color: $base-color;
        display: inline-block;
        font-size: 14px;
        font-weight: 900;
        text-align: center;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
      }
    }
  }
}



// Banner
ul.local-sidebar-banner {
  display: block;
  margin-top: 28px;
  @include media(md){
    display: flex;
    flex-flow: row wrap;
  }
  @include media(lg){
    display: block;
  }
  li {
    margin-bottom: 12px;
    text-align: center;
    @include media(md){
      flex: 0 0 50%;
    }
    @include media(lg){
      flex: none;
    }
  }
}
