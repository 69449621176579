@charset 'UTF-8';

// font size
//
$label_font_size: .75em;



// label 上下の padding
//
$label_padding_vertical_array: (
  sm: .425em,
  md: .5em,
  lg: .4em
) !default;



// label 左右の padding
//
$label_padding_horizontal_array: (
  sm: .6em,
  md: .6em,
  lg: .6em
) !default;



// 角丸
//
$label_border_radius: 0px !default;



// アウトライン時のボーダーの太さ
$label_border: 1px !default;



// Color
//
$label_colors_array: (
  'base'     : ( 'bg': $light-base-color,   'color': $gray-700 ),
  'accent'    : ( 'bg': $light-accent-color,  'color': $gray-700 ),
  'schedule': ( 'bg': $light-schedule-color, 'color': $schedule-color ),
  'closure' : ( 'bg': $light-closure-color,  'color': $closure-color ),
  'section' : ( 'bg': $light-section-color,  'color': $section-color ),
  'access'  : ( 'bg': $light-access-color,   'color': $access-color ),
  'important': ( 'bg': #e64949,   'color': $white-color ), // 病院からのお知らせ：重要
  'info'     : ( 'bg': $accent-color, 'color': $white-color ), // 病院からのお知らせ：お知らせ
  'recruit'  : ( 'bg': $access-color,   'color': $white-color )  // 病院からのお知らせ：採用情報
) !default;

// outline
//
$label_outline_colors_array: (
  'base'    : ( 'bg' : $white-color, 'color' : $base-color,   'border' : $base-color ),
  'accent'   : ( 'bg' : $white-color, 'color' : $accent-color,  'border' : $accent-color ),
  'schedule': ( 'bg': $white-color, 'color': $schedule-color, 'border': $schedule-color ),
  'closure' : ( 'bg': $white-color, 'color': $closure-color,  'border': $closure-color ),
  'section' : ( 'bg': $white-color, 'color': $section-color,  'border': $section-color ),
  'access'  : ( 'bg': $white-color, 'color': $access-color,   'border': $access-color )
) !default;
