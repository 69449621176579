@charset 'UTF-8';

$link-btn-border: 1px;
$link-btn-radius: 4px;

$link-btn-font-size: 14px;



// button
//
.button {
  background: multi-array( $button_color_array, 'base', 'bg' );
  border: $link-btn-border solid transparent;
  border-radius: $link-btn-radius;
  filter: drop-shadow( 2px 2px 0px rgba( 0, 0, 0, .1 ) );
  color: multi-array( $button_color_array, 'base', 'color' );
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: $link-btn-font-size;
  font-weight: 500;
  line-height: 1.42875 !important;
  text-align: left;
  -webkit-appearance: none;
  text-decoration: none;
  padding: ( $link-btn-font-size * .7142857 ) $link-btn-font-size;
  position: relative;
  width: auto;
  transition: background $transition;

  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  // hover
  &:hover {
    background: multi-array( $button_hover_color_array, 'base', 'bg' );
    color: multi-array( $button_hover_color_array, 'base', 'color' );
  }

  // span
  span {
    // icon
    &.icon {
      &::before {
        @include button_icon;
        margin-right: 8px;
      }
      &.right {
        &::after {
          @include button_icon;
          margin-left: .6em;
        }
        &::before {
          content: none;
        }
      }
      // icon-type
      @each $name, $icon in $icon_type_array {
        &.#{$name} {
          &::before {
            content: $icon !important;
          }
          &.right  {
            &::after {
              content: $icon !important;
            }
            &::before {
              content: none !important;
            }
          }
        }
      }
      // icon color
      @each $name, $color in $color_pattern_array {
        &.ic-#{$name} {
          &::after, &::before {
            color: $color;
          }
        }
      }
    } // .icon
  } // span

  // Color Pattern
  @each $name, $value in $button_color_array {
    &.#{$name} {
      background: map-get( $value, 'bg' );
      color: map-get( $value, 'color' );
      &:hover {
        background: multi-array( $button_hover_color_array, #{$name}, 'bg' );
        color: multi-array( $button_hover_color_array, #{$name}, 'color' );
      }
    }
  }

  // button outline
  //
  &.outline {
    background: multi-array( $button-outline-color-array, 'base', 'bg' );
    border: $link-btn-border solid multi-array( $button-outline-color-array, 'base', 'border' );
    color: multi-array( $button-outline-color-array, 'base', 'color' );
    text-shadow: none;
    // Hover
    &:hover {
      background: multi-array( $button-outline-hover-color-array, 'base', 'bg' );
      color: multi-array( $button-outline-hover-color-array, 'base', 'color' );
      text-decoration: none;
    }
    // Color Pattern
    @each $name, $value in $button-outline-color-array {
      &.#{$name} {
        background: map-get( $value, 'bg' );
        border: $link-btn-border solid map-get( $value, 'border' );
        color: map-get( $value, 'color' );
        &:hover {
          background: multi-array( $button-outline-hover-color-array, #{$name}, 'bg' );
          color: multi-array( $button-outline-hover-color-array, #{$name}, 'color' );
        }
      }
    }
  }

} // .button



// 外来担当医表・休診代診のお知らせ・診療科目・交通アクセス
.generic-button {
  border: $button_border solid $body-text-color;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  font-size: .875em;
  line-height: 1.42875 !important;
  text-align: left;
  -webkit-appearance: none;
  text-decoration: none;
  padding: 2px;
  position: relative;
  width: 100%;

  i {
    font-size: 2em;
    margin-right: .25em;
  }
  span {
    border: 1px solid $body-text-color;
    border-radius: 4px;
    color: $body-text-color;
    display: flex;
    align-items: center;
    padding: calc(.625em + 1px) 1.125em .625em;
    transition: background $transition;
    width: 100%;
  }
  p {
    font-size: 1.125em;
  }
  // 外来担当医表
  &.schedule {
    i {
      color: $schedule-color;
    }
  }
  // 休診・代診のお知らせ
  &.closure {
    i {
      color: $closure-color;
    }
  }
  // 診療科目
  &.section {
    i {
      color: $section-color;
    }
  }
  // 交通アクセス
  &.access {
    i {
      color: $access-color;
    }
  }

  // hover
  &:hover {
    i {
      color: $white-color;
    }
    span {
      color: $white-color;
    }
    // 外来担当医表
    &.schedule {
      border-color: $schedule-color;
      span {
        background: $schedule-color;
        border-color: $schedule-color;
      }
    }
    // 休診・代診のお知らせ
    &.closure {
      border-color: $closure-color;
      span {
        background: $closure-color;
        border-color: $closure-color;
      }
    }
    // 診療科目
    &.section {
      border-color: $section-color;
      span {
        background: $section-color;
        border-color: $section-color;
      }
    }
    // 交通アクセス
    &.access {
      border-color: $access-color;
      span {
        background: $access-color;
        border-color: $access-color;
      }
    }
  } // hover
}



// 診療科案内 部門案内 ボタン
.section-button {
  border: $button_border solid $body-text-color;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  font-size: 1em;
  line-height: 1.42875 !important;
  text-align: left;
  -webkit-appearance: none;
  text-decoration: none;
  padding: 2px;
  position: relative;
  width: 100%;

  span {
    border: 1px solid $body-text-color;
    border-radius: 4px;
    color: $body-text-color;
    display: flex;
    align-items: center;
    padding: calc(.5em + 1px) .5em .5em;
    transition: background $transition;
    width: 100%;
    p {
      margin: 0;
    }
  }
  img {
    display: block;
    margin-right: 1em;
    width: auto;
    height: 2.5em;
  }
  &:hover {
    span {
      background: lighten( $base-color, 25% );
    }
  }
  // 外部リンク
  &.outside {
    &:after {
      content: '\f35d';
      font-family: $icon_font_family;
      color: $base-color;
      display: inline-block;
      font-weight: 900;
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
    }
  }
}




// FORM
// input button
.input-button {
  background: $base-color;
  border-radius: 8px;
  cursor: pointer;
  color: $white-color;
  display: inline-block;
  font-size: 1em;
  line-height: 1.42875 !important;
  text-align: center;
  -webkit-appearance: none;
  text-decoration: none;
  padding: .625em 1em;
  transition: background $transition;
  &:hover {
    background: lighten( $base-color, 5% );
  }
  // Color Pattern
  @each $name, $value in $theme_colors_array {
    &.#{$name} {
      background: $value;
      &:hover {
        background: darken( $value, 5% );
      }
    }
  }
  // expanded
  &.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
