@charset 'UTF-8';

ul, ol {
  list-style: none;
  line-height: 200%;
  margin: 0;
  padding: 0;

  &.li-mb {
    li:not(:last-child) {
      margin-bottom: .8em;
    }
    ul, ol {
      margin-top: .8em;
    }
  }

  &.indent {
    margin-left: 1em;
    ul {
      margin-top: .8em;
    }
  }
}



// ul の装飾
//
ul.disc {
  list-style: disc outside;
  padding-left: 1.4em;
  ul {
    list-style: circle outside;
    padding-left: 1em;
  }
}

ul.square {
  li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    &:before {
      font-family: $icon_font_family;
      content: '\f0c8';
      color: $base-color;
      font-weight: 900;
      margin-right: .4em;
      position: absolute;
      left: 0;
    }
    > ul.disc {
      li {
        padding-left: 0;
        &:before {
          content: none;
        }
      }
    }
  }
}

ul.circle {
  li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    &:before {
      font-family: $icon_font_family;
      content: '\f111';
      color: $base-color;
      font-weight: 900;
      margin-right: .4em;
      position: absolute;
      left: 0;
    }
  }
}

ul.asterisk {
  li {
    line-height: inherit;
    padding-left: 1.2em;
    position: relative;
    &:before {
      font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Sans', 'Original Yu Gothic', 'Yu Gothic', sans-serif !important;
      content: '※';
      color: inherit;
      margin-right: .4em;
      position: absolute;
      left: 0;
    }
  }
}

.list-row {
  display: flex;
  flex-flow: row wrap;
  li {
    &:not(:last-child){
      margin-right: 1em;
    }
  }
}

// ol
//
ol.num {
  list-style: decimal outside;
  padding-left: 1em;
  ol {
    list-style: decimal outside;
    padding-left: 1em;
  }
}

ol.maru {
  counter-reset: number;
  list-style: decimal outside;
  list-style-type: none !important;
  //padding-left: 1em;
  li {
    position: relative;
    //line-height: 1.625em;
    padding: 0 0 0 30px;
    &:before {
      position: absolute;
      counter-increment: number;
      content: counter(number);
      // 以下数字のデザイン変える
      display: inline-block;
      background: $base-color;
      color: $white-color;
      font-weight: 700;
      font-size: .8em;
      border-radius: 50%;
      left: 0;
      width: 18px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      // 以下 上下中央寄せのため
      top: 50%;
      transform: translateY(-50%);
    }
    @include media(lg){
      &:before {
        width: 22px;
        height: 22px;
        line-height: 20px;
      }
    }
  }
  ol {
    list-style: decimal outside;
    padding-left: 1em;
  }
}



// dl
//
dl {
  dt {
    font-weight: bold;
    margin-bottom: .4em;
    &:nth-of-type(n+2) {
      margin-top: 1.2em; }
  }
  dd {
    line-height: 1.4;
  }

  &.indent {
    dd {
      padding-left: 1em;
    }
  }
}
