@charset 'UTF-8';

// icon
//
.icon {
  span {
    &::after {
      @include icon_default;
      margin-left: .25em;
    }
    &.left {
      &::after { content: none; }
      &::before {
        @include icon_default;
        margin-right: .25em;
      }
    }
    @each $name, $icon in $icon_type_array {
      &.#{$name} {
        &::after {
          content: $icon !important;
        }
        &.left  {
          &::after {
            content: none !important;
          }
          &::before {
            content: $icon !important;
          }
        }
      }
    }
  }
  // color pattern
  @each $name, $color in $color_pattern_array {
    & span.ic-#{$name} {
      &::after, &::before {
        color: $color;
      }
    }
  }
}
