@charset 'UTF-8';

// 明朝体
$font-serif: '游明朝', YuMincho, 'Hiragino Mincho ProN W3', 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;



// 新型コロナウイルス感染症に関する当院の対応
.press-release-wrapper {
  padding: map-get( $container_padding_array, sm );
  width: 100%;
  @include media(md) {
    padding: map-get( $container_padding_array, md );
  }
  @include media(lg) {
    padding: map-get( $container_padding_array, lg );
  }
}

.press-release-inner {
  margin: 0 auto;
  @include width_container( $container_width_array );

  .covid {
    border: 2px solid $red-color;
    color: $red-color;
    display: block;
    padding: 1em;
    text-align: center;
  }
}



// 表組みのラッパー
.top-slider-hero-inner-wrapper {
  margin-top: 16px;
  padding: 0 map-get( $container_padding_array, sm );
  position: relative;
  width: 100%;
  height: auto;
  z-index: 2;
  @include media(lg){
    margin-top: 0;
    padding: 0 map-get( $container_padding_array, lg );
    position: absolute;
    bottom: 5%;
    left: 0;
  }
}

.top-slider-hero-inner {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @include width_container( $container_width_array );
  @include media(lg){
    flex-direction: row;
    justify-content: flex-end;
  }
}

// 告知エリア
.top-slider-notification-container {
  background: $white-color;
  margin-bottom: 24px;
  padding: 16px;
  @include media(lg){
    flex: 1;
    margin-bottom: 0;
    margin-right: 24px;
  }
  // 非表示
  &.disabled {
    display: none;
  }
}

.top-slider-infomation-container {
  background: $white-color;
  padding: 16px;
  width: 100%;
  @include media(lg){
    width: auto;
    max-width: 590px;
  }
}

.top-slider-infomation-item {
  & + & {
    margin-top: 12px;
  }
}

dl.top-slider-infomation {
  display: block;
  @include media(lg){
    display: flex;
  }
  dt {
    background: $base-color;
    color: $white-color;
    padding: 6px 0;
    text-align: center;
    i {
      margin-right: 8px;
    }
    @include media(lg){
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 18px;
      width: 144px;
      span {
        display: block;
      }
    }
  }
  dd {
    margin-top: 12px;
    text-align: center;
    ul {
      line-height: 1.8;
      li {
        .time-code {
          color: $base-color;
          font-size: 125%;
          font-weight: 700;
        }
      }
    }
    @include media(lg){
      display: flex;
      align-items: center;
      margin-top: 0;
      text-align: left;
    }
  }
}

// 夜間時間外担当科表
.night-work-file {
  text-align: center;
  a {
    color: $body-text-color;
    display: inline-block;
    margin-top: 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color $transition;

    &:before, &:after {
      display: inline-block;
      font-family: $icon_font_family;
      font-weight: 900;
    }

    &:before {
      content: map-get( $icon_type_array, 'default' );
      margin-right: 8px;
    }

    &:after {
      content: map-get( $icon_type_array, 'pdf' );
      font-weight: 400;
      margin-left: 8px;
    }

    &:hover {
      color: $base-color;
    }
  }
}



// 4つのカテゴリー
//
.top-category-button-wrapper {
  display: flex;
  flex-flow: row wrap;
  margin: -8px;
  padding: 16px 0;
  @include media(lg){
    padding: 32px 0;
  }
}

.top-category-button-item {
  display: flex;
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  padding: 8px;
  @include media(lg){
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }
}

// card
//

$card-transition: all .3s cubic-bezier(.47,0,.74,.71) 0s;

.top-category-card {
  border: $button_border solid $body-text-color;
  padding: 2px;
  width: 100%;
}

.top-category-card-inner {
  border: 1px solid $body-text-color;
  padding: 0 0 24px;
  height: 100%;
  overflow: hidden;
  text-align: center;
  @include media(lg){
    padding: 0 0 24px;
  }
  a {
    text-decoration: none;
  }
  // hover
  &:hover {
    .category-heading,
    .category-heading-sub {
      color: $white-color;
    }
    .circle {
      transform: translateX(-50%) scale(9);
      @include media(lg){
        transform: translateX(-50%) scale(6);
      }
    }
    .category-heading-body {
      .text {
        color: $white-color;
      }
    }
    .lower-icon {
      span {
        opacity: 1;
      }
      i {
        color: $white-color;
        transform: translateX(270%);
      }
    }
  } // hover
}

.top-category-card-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  @include media(lg){
    height: 140px;
  }
}

h2.category-heading {
  color: $body-text-color;
  font-family: $font-serif;
  font-size: 16px;
  line-height: 1.2;
  transition: $card-transition;
  @include media(lg){
    font-size: 20px;
  }
}

.category-heading-sub {
  font-size: 10px;
  transition: $card-transition;
}

.category-heading-icon {
  position: relative;
  .circle {
    //background: $base-color;
    border-radius: 50%;
    color: $white-color;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) scale(1);
    transition: $card-transition;
    width: 52px;
    height: 52px;
    z-index: -1;
    @include media(lg){
      width: 96px;
      height: 96px;
    }
  }
  i {
    color: $white-color;
    font-size: 28px;
    line-height: 52px;
    @include media(lg){
      font-size: 60px;
      line-height: 96px;
    }
  }
}

.category-heading-body {
  display: none;
  @include media(lg){
    display: block;
  }
  .text {
    color: $body-text-color;
    font-size: 14px;
    margin: 32px auto 0;
    text-align: left;
    width: 164px;
    transition: $card-transition;
  }
  .lower-icon {
    margin-top: 16px;
    position: relative;
    span {
      color: $white-color;
      font-size: 12px;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: $card-transition;
    }
    i {
      font-size: 16px;
      transition: $card-transition;
    }
  }
}



// Banner carousel
//

.banner-carousel-wrapper {
  padding: 16px 0;
  @include media(lg){
    padding: 30px 0;
  }
}

.banner-carousel {
  .slick-list {
    margin: 0 10px;
  }
  .slick-slide {
    margin: 0 6px;
  }
}

.slide-arrow {
  display: block;
  width: auto;
  height:42px;
}
