@charset 'UTF-8';

// ベースカラーパターン
//
$base-color:    #e58ba1 !default;
$accent-color:   #5a7dad !default;
$schedule-color: #fa7f88 !default; // 外来担当医表
$closure-color : #93b74b !default; // 休診代診
$section-color : #69add7 !default; // 診療科目
$access-color  : #dfb573 !default; // 交通アクセス

// 明るい背景色
$light-base-color: #FFF7F7 !default;
$light-accent-color: #EFF7FF !default;
$light-schedule-color: #f9eff0 !default;
$light-closure-color : #f6f8f0 !default;
$light-section-color : #e2eff7 !default;
$light-access-color  : #fbf6ee !default;
$light-gray-color  : #f8f9fa !default;

// グレーパターン
//
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$gray-dark: #333 !default;

// 汎用カラー
//
$white-color:  #fff    !default; // 白
$black-color:  #000    !default; // 黒
$red-color:    #b0120a !default; // 赤
$yellow-color: #fff000 !default; // 黄
$orange-color: #f18f2c !default; // 橙
$green-color:  #63a764 !default; // 緑
$blue-color:   #005fcc !default; // 青
$pink-color:   #ff0066 !default; // ピンク

$common-bg-color: #edf0f2;



// 上記でカラーを追加した場合、下記の配列にも追加する（ '使用する名前': 変数;
//
$theme-colors-array: (
  'base'    : $base-color,
  'accent'   : $accent-color,
  'schedule': $schedule-color,
  'closure' : $closure-color,
  'section' : $section-color,
  'access'  : $access-color,
  'light-base'    : $light-base-color,
  'light-accent'   : $light-accent-color,
  'light-schedule': $light-schedule-color,
  'light-closure' : $light-closure-color,
  'light-section' : $light-section-color,
  'light-access'  : $light-access-color,
  'light-gray'    : $light-gray-color
) !default;

$light-color-array: (
  'base'    : $light-base-color,
  'accent'   : $light-accent-color,
  'schedule': $light-schedule-color,
  'closure' : $light-closure-color,
  'section' : $light-section-color,
  'access'  : $light-access-color,
  'gray'    : $light-gray-color
) !default;

$gray-colors-array: (
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900,
  'gray-dark': $gray-dark
) !default;

$common-colors-array: (
  'white':  $white-color,
  'black':  $black-color,
  'red':    $red-color,
  'yellow': $yellow-color,
  'orange': $orange-color,
  'green':  $green-color,
  'blue':   $blue-color,
  'pink':   $pink-color
) !default;

// 上記のカラー配列をまとめる
//
$basic-colors-array: map-merge( $gray-colors-array, $common-colors-array ) !default;
$color-pattern-array: map-merge( $theme-colors-array, $basic-colors-array ) !default;
