@charset 'UTF-8';

// variable
$pagination_active_class: '.current';



.pagination-wrapper {
  width: 100%;
}



.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  text-align: center;
  margin-top: 3em;

  a,
  #{$pagination_active_class},
  span {
    border-radius: 3px;
    display: block;
    padding: .8em calc( 1em - 1px );
  }

  a {
    background: $white-color;
    border: 1px solid $gray-500;
    color: $body_text_color;
    transition: border $transition;
    &:hover {
      border: 1px solid $gray-800;
    }
  }
  #{$pagination_active_class} {
    background: $base-color;
    border: 1px solid $base-color;
    color: $white-color;
  }

  span {
    border: 1px solid $gray-400;
    color: $gray-600;
  }

  li {
    margin: 0 2px;
  }

}
