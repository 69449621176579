@charset 'UTF-8';

// top only menu
// -------
.top-navigation-wrapper {
  background: $common-bg-color;
  margin-top: 16px;
  padding: 0 map-get( $container_padding_array, sm );
  @include media(md){
    padding: 0 map-get( $container_padding_array, md );
  }
  @include media(lg) {
    margin-top: 0;
    padding: 0 map-get( $container_padding_array, lg );
  }
}

.top-navigation-container {
  margin: 0 auto;

  @include media(lg){
    @include width_container( $container_width_array );
  }
}

ul.top-navigation {
  display: flex;
  flex-flow: row wrap;
  li {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    position: relative;
    @include media(lg){
      flex: 0 0 25%;
      width: 25%;
      max-width: 25%;
    }
    a {
      color: $body-text-color;
      display: block;
      font-size: 14px;
      padding: 16px;
      text-align: center;
      text-decoration: none;
      transition: background $transition;
      @include media(lg){
        font-size: 18px;
        padding: 36px 0;
      }
      &:hover {
        background: $white-color;
      }
    }
    span {
      color: $base-color;
      display: block;
      font-size: 12px;
    }
    &:before {
      content: '';
      background: #ccc;
      display: block;
      width: 1px;
      height: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &:nth-of-type(even){
      &:after {
        content: '';
        background: #ccc;
        display: block;
        width: 1px;
        height: 50%;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
    &:nth-of-type(-n+2){
      border-bottom: 1px solid #ccc;
      @include media(lg){
        border-bottom: none;
      }
    }
    @include media(lg){
      &:nth-of-type(even){
        &:after {
          content: none;
        }
      }
      &:last-child {
        &:after {
          content: '';
          background: #ccc;
          display: block;
          width: 1px;
          height: 50%;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
    }
  }
}



// Global Navigation
// -------
.global-navigation-wrapper {
  background: $common-bg-color;
  margin-top: map-get( $header_height_array, sm );
  position: fixed;
  top: 0;
  right: 0;
  width: 80vw;
  height: 100%;
  z-index: 9;
  transform: translateX(100%);
  transition: transform $transition;
  // OPEN
  #{$burger_open_class} & {
    transform: translateX(0);
  }
  @include media(md){
    width: 36vw;
  }
  @include media(lg){
    background: transparent;
    margin-top: 0;
    position: relative;
    top: unset;
    transform: translateX(0);
    width: 100%;
    height: auto;
  }
}

// body の挙動
body#{$burger_open_class} {
  overflow: hidden;
}

// container
// -------
.global-navigation-container {
  margin: 0 auto;
  overflow-y: auto;
  padding: 16px 16px map-get( $header_height_array, sm );;
  height: 100%;
  @include media(lg){
    overflow-y: inherit;
    padding: 0;
    @include width_container( $container_width_array );
  }
}

// navigation
// ----------
ul.global-navigation {
  display: block;
  line-height: 1;
  width: 100%;
  @include media(lg){
    display: flex;
    justify-content: flex-end;
    padding: 0;
    position: relative;
  }

  > li {
    border-bottom: 1px solid $gray-300;
    &:first-child {
      border-top: 1px solid $gray-300;
    }
    @include media(lg){
      border: none;
      &:first-child {
        border-top: none;
      }
    }
    &.gnav-trigger {
      cursor: pointer;
      display: block;
      // PC
      @include media(lg){
        display: inline-block;
        flex: 0 0 auto;

        // 最初以外
        &:nth-of-type(n+2){
          margin-left: 24px;
        }
        // hover
        &:hover {
          .gnav-child-menu-container {
            pointer-events: auto;
            opacity: 1;
            visibility: visible;
          }
          .gnav-heading {
            &:after {
              transform: scaleX(1);
            }
          }
        }
        // current
        &.current {
          .gnav-heading {
            &:after {
              transform: scaleX(1);
            }
          }
        }
      }
    }
  }

  .gnav-heading {
    color: $body-text-color;
    display: block;
    font-size: 14px;
    padding: 12px 0 12px 16px;
    text-decoration: none;
    &:before {
      content: '\f192';
      color: $base-color;
      display: inline-block;
      font-family: $icon_font_family;
      font-size: 1em;
      font-weight: 900;
      margin-right: 8px;
    }
    // PC
    @include media(lg){
      font-size: 16px;
      padding: 12px 0 4px;
      text-align: center;
      position: relative;
      &:before {
        content: none;
      }
      // 下部のボーダー
      &:after {
        background: $base-color;
        content: '';
        display: inline-block;
        width: 100%;
        height: 3px;
        transform-origin: center;
        transform: scaleX(0);
        transition: all $transition;
      }
    }
  }

  .gnav-sub-title {
    color: $closure-color;
    font-size: 12px;
    margin-left: 8px;
    @include media(lg){
      display: block;
      font-size: 12px;
      margin-left: 0;
    }
  }
}



.gnav-child-menu-container {
  display: none;
  @include media(lg){
    background: $white-color;
    box-shadow: -5px 5px 6px -3px rgba( 100, 100, 100, 0.4 ),
                5px 5px 6px -3px rgba( 100, 100, 100, 0.4 );
    display: block;
    //padding: 36px;
    padding: 20px 36px 10px 36px;
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    width: 100%;
    pointer-events: none;
    transition: opacity .125s, visibility .125s;
    opacity: 0;
    visibility: hidden;
  }
}

.gnav-child-menu-inner {
  display: block;
  @include media(lg){
    display: flex;
    margin: 0 -24px;
    & + & {
      border-top: 1px solid $gray-300;
      //margin-top: 36px;
      margin-top: 0;
      padding-top: 36px;
    }
  }
}

.gnav-child-menu-pc {
  display: none;
  @include media(lg){
    display: block;
    a {
      &:hover {
        .gnav-child-menu-heading {
          color: $link_hover_color;
        }
        .gnav-child-menu-images {
          opacity: .7;
        }
      }
    }
  }
}


// Child menu
//
.gnav-child-menu,
.gnav-child-menu-pc {
  @include media(lg){
    flex: 0 0 percentage(1/3);
    padding: 0 24px;
  }
}

.gnav-child-menu-heading {
  @include media(lg){
    color: $gray-dark;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 0 0 8px 0;
    transition: color $transition;
    &:after {
      content: none;
    }
    &:before {
      content: '\f192';
      color: $base-color;
      display: inline-block;
      font-family: $icon_font_family;
      font-size: 1em;
      font-weight: 900;
      margin-right: 8px;
    }
  }
  span {
    display: none;
    @include media(lg){
      color: $section-color;
      display: inline-block;
      font-size: .75em;
      margin-left: 12px;
    }
  }
}

.gnav-child-menu-images {
  width: 100%;
  //height: 200px;
  height: 180px;
  overflow: hidden;
  transition: opacity $transition;
}

ul.gnav-child-menu {
  display: block;

  li {
    &:not(.taxis){
      margin-left: 1em;
    }
    @include media(lg){
      //
    }
    a {
      background: #edf0f2;
      color: $body-text-color;
      display: block;
      font-size: 12px;
      line-height: 1.4;
      padding: 12px 12px 12px 48px;
      position: relative;
      text-decoration: none;
      transition: color $transition;
      @include media(lg){
        background: transparent;
        font-size: 14px;
        //padding: 12px 24px 12px 24px;
        padding: 10px 24px 10px 24px;
      }
      &:hover {
        color: $base-color;
        @include media(lg){
          color: inherit;
          background: $gray-100;
        }
      }
      &:before {
        content: '\f054';
        color: $base-color;
        display: inline-block;
        font-family: $icon_font_family;
        font-size: 10px;
        font-weight: 900;
        margin-right: 12px;
        position: absolute;
        top: 50%;
        left: 32px;
        transform: translateY(-50%);
        @include media(lg){
          font-size: 14px;
          left: 8px;
        }
      }
      &.index {
        font-weight: 700;
        &:before {
          font-size: 13px;
        }
        @include media(lg){
          display: none;
        }
      }
    }
  }
}

// SP only menu
.gnav-sp-only {
  display: block;
  @include media(lg){
    display: none;
  }
}

ul.generic-nav {
  border-bottom: 1px solid $gray-300;
  padding: 16px 0;
  li {
    a {
      color: $body-text-color;
      font-size: 12px;
      padding: 12px 0 12px 16px;
      position: relative;
      text-decoration: none;
      transition: color $transition;
      &:before {
        display: inline-block;
        font-family: $icon_font_family;
        font-size: 14px;
        font-weight: 900;
        margin-right: 8px;
        text-align: center;
        width: 14px;
      }
      &:hover {
        color: $base-color;
      }
      // 外来担当医表
      &.schedule {
        &:before {
          content: map-get( $icon_type_array , 'schedule' );
          color: $schedule-color;
        }
      }
      // 交通アクセス
      &.access {
        &:before {
          content: map-get( $icon_type_array , 'access' );
          color: $access-color;
        }
      }
      // 休診・代診のお知らせ
      &.closure {
        &:before {
          content: map-get( $icon_type_array , 'closure' );
          color: $closure-color;
        }
      }
      // 診療科目
      &.section {
        &:before {
          content: map-get( $icon_type_array , 'section' );
          color: $section-color;
        }
      }
    }
  }
}

ul.utility-nav {
  border-bottom: 1px solid $gray-300;
  padding: 16px 0;
  li {
    a {
      color: $body-text-color;
      font-size: 12px;
      padding: 12px 0 12px 16px;
      position: relative;
      text-decoration: none;
      transition: color $transition;
      &:before {
        content: map-get( $icon_type_array , 'angle' );
        color: inherit;
        display: inline-block;
        font-family: $icon_font_family;
        font-size: 14px;
        font-weight: 900;
        margin-right: 8px;
        text-align: center;
        width: 14px;
      }
      &:hover {
        color: $base-color;
      }
    }
  }
}

ul.hospital-utility-info {
  font-size: 13px;
  padding: 16px 0 12px 16px;
  li {
    line-height: 1.6;
  }
}
