@charset 'UTF-8';

// variable
$accordion_open_class_name: '.active';



.accordion-wrapper {
  width: 100%;
}



.accordion {
  .accordion-heading {
    background: $base-color;
    border-bottom: 1px solid darken( $base-color, 5% );
    color: $white-color;
    cursor: pointer;
    font-size: 1em;
    outline: none;
    margin: 0;
    padding: 1em 2.5em 1em 1em;
    position: relative;
    transition: background $transition;
    width: 100%;
    &:hover {
      background: darken( $base-color, 5% );
    }
    // arrow
    &:after {
      background-image: url('/images/common/circle-plus-white.svg');
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      pointer-events: none;
      position: absolute;
      top: 50%;
      right: 1em;
      width: 16px;
      height: 16px;
      transform: translateY(-50%);
      @include media(lg){
        width: 20px;
        height: 20px;
      }
    }
    &#{$accordion_open_class_name} {
      &:after {
        background-image: url('/images/common/circle-minus-minus.svg');
      }
    }
  }
  .accordion-contents {
    overflow: hidden;
    max-height: 0;
    transition: max-height $transition;
  }
  .accordion-contents-inner {
    background: $white-color;
    padding: 1em;
  }
}
