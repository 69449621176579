@charset 'UTF-8';

// Footer mega menu
//

.footer-mega-menu-wrapper {
  position: relative;
}

// 可視と不可視
.footer-nav-mega-menu-sm {
  display: block;
  @include media(lg){
    display: none;
  }
}
.footer-nav-mega-menu-pc {
  display: none;
  @include media(lg){
    display: block;
  }
}

// Menu

ul.footer-mega-menu {
  display: none;
  @include media(lg){
    display: flex;
    flex-flow: row wrap;
    margin: -10px;
    width: 100%;
  }
  //
  li.trigger {
    display: block;
    @include media(lg){
      flex: 0 0 25%;
      width: 25%;
      max-width: 25%;
      padding: 10px;
    }
  }
  .menu-heading {
    border-bottom: 1px solid $gray-200;
    cursor: pointer;
    color: $body-text-color;
    display: flex;
    align-items: flex-end;
    border-left: none;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 8px;
    padding: 16px 0 16px 24px;
    position: relative;
    text-decoration: none;
    transition: color $transition;
    &:before {
      content: '\f192';
      color: $base-color;
      display: inline-block;
      font-family: $icon_font_family;
      font-size: 16px;
      font-weight: 900;
      text-align: center;
      position: absolute;
      top: calc(50% + 1px);
      left: 0;
      transform: translateY(-50%);
    }
    &:hover {
      color: $base-color;
    }
  }
  .sub-title {
    color: $section-color;
    display: block;
    font-size: 12px;
    margin-left: 8px;
  }
}


// Child menu

ul.child-menu {
  width: 100%;
  li {
    a {
      background: $white-color;
      color: $body-text-color;
      display: block;
      font-size: 12px;
      line-height: 1.2;
      padding: 10px 24px;
      position: relative;
      text-decoration: none;
      transition: background $transition;
      &:hover {
        background: $gray-100;
        color: $base-color;
      }
      &:after {
        content: '\f105';
        color: inherit;
        display: inline-block;
        font-family: $icon_font_family;
        font-size: 14px;
        font-weight: 900;
        text-align: center;
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
      }
      // 外部リンク
      &.outside {
        &:after {
          content: '\f35d';
          font-size: 12px;
        }
      }
    }
  }
}



// Child menu のコンテナ
.child-menu-container {
  display: none;
  // PC
  @include media(lg){
    display: flex;
    flex-flow: row wrap;
    &.child-row { // 調整用
      margin: -10px;
    }
  }
}
