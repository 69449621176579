@charset 'UTF-8';

//
//  病院からのお知らせ
//



$information-class: '.all-information'; // 病院のお知らせ一覧ページとの差別化を図るためのクラス名



.hospital-information-wrapper {
  margin: 16px 0 32px;
}

.hospital-information-container {
  background: $common-bg-color;
  border: $button_border solid $body-text-color;
  padding: 2px;
}

.hospital-information-inner {
  border: 1px solid $body-text-color;
  padding: 8px;
  @include media(lg){
    padding: 18px;
  }
}

.hospital-information-heading {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 14px;
  .heading-icon {
    background: $base_color;
    margin-right: 16px;
    padding: 6px 8px;
    i {
      color: $white-color;
    }
  }
}



// 病院からのお知らせ
ul.hospital-information-list {
  @include media(lg){
    display: flex;
    flex-flow: row wrap;
    margin: -7px;
  }

  li {
    display: block;
    @include media(lg){
      display: flex; // 高さを揃えるため
      flex: 0 0 25%;
      width: 25%;
      max-width: 25%;
      padding: 7px;
    }

    &:nth-of-type(2n){
      .list-item-container {
        background: #fcfaf5;
        @include media(lg){
          background: $white-color;
        }
      }
    }

    a {
      color: $body-text-color;
      display: block;
      margin-top: 8px;
      text-decoration: none;
      transition: color $transition;
      &:hover {
        color: $base-color;
      }
      @include media(lg){
        margin-top: 12px;
      }
    }
  }
}

.list-item-container {
  background: $white-color;
  padding: 8px 28px 8px 10px;
  position: relative;
  width: 100%; // 高さを揃えたために横幅が足りなくなるので
  &:after {
    content: '\f054';
    color: inherit;
    display: inline-block;
    font-family: $icon_font_family;
    font-weight: 900;
    text-align: center;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    transition: transform $transition;
  }
  @include media(lg){
    padding: 14px;
    &:after {
      content: none;
    }
  }
}

.list-item {
  display: block;
  align-items: center;
  font-size: 12px;
  @include media(lg){
    display: block;
    font-size: 14px;
  }

  .date {
    color: $gray-600;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    white-space: nowrap;
    @include media(lg){
      font-size: 14px;
    }
  }

  .category {
    display: flex;
    align-items: center;
    margin-top: 8px;
    @include media(lg){
      margin-top: 12px;
    }
  }

  .news {
    line-height: 1.4;
    margin-top: 8px;
    @include media(lg){
      margin-top: 12px;
    }
  }
}



// 病院からのお知らせの一覧ページ

// くるくるアイコン
.spin-icon-wrapper {
  .spin-inner {
    text-align: center;
  }
  i {
    color: $gray-500;
    display: inline-block;
    font-size: 5em;
  }
}

ul.hospital-information-archives-list {
  margin: -12px 0;
  @include media(lg){
    margin: -24px 0;
  }
  li {
    border-bottom: 1px solid $gray-200;
    display: block;
    padding: 12px 0;
    @include media(lg){
      padding: 24px 0;
    }
  }

}

.archives-item-container {
  display: block;
  @include media(md){
    display: flex;
    align-items: flex-start;
  }
}

// 日付
.archives-date {
  color: $gray-600;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 8px;
  white-space: nowrap;
  @include media(md){
    flex: 0 0 96px;
  }
  @include media(lg){
    font-size: 16px;
    flex: 0 0 116px;
  }
}

// 本文
.archives-item {
  display: block;
  line-height: 1;
}

// 記事
.archives-article {
  display: block;
  @include media(md){
    display: flex;
    align-items: center;
  }

  & + & {
    margin-top: 12px;
  }

  .category {
    display: block;
    margin-bottom: 4px;
    @include media(md){
      margin-bottom: 0;
      margin-right: 20px;
    }
    @include media(lg){
      margin-right: 24px;
    }
  }

  .article {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4;
    @include media(lg){
      font-size: 15px;
    }
    a {
      color: $body-text-color;
      text-decoration: none;
      transition: color $transition;
      &:hover {
        color: $base-color;
      }
    }
  }
}




.hospital-information-lower {
  @include media(lg){
    display: flex;
    justify-content: flex-end;
  }
}

// 一覧を見る
.all-info {
  background: $common-bg-color;
  border-radius: 0 0 6px 6px;
  position: relative;
  @include media(lg){
    width: 25%;
  }
  a {
    color: $body-text-color;
    display: block;
    font-size: 12px;
    padding: 12px 0;
    text-align: center;
    text-decoration: none;

    @include media(lg){
      &:after {
        content: '\f054';
        color: inherit;
        display: inline-block;
        font-family: $icon_font_family;
        font-weight: 900;
        text-align: center;
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
      }
    }
  }
}



// お知らせ詳細ページ
.article-detail-container {
  position: relative;
}

.article-heading {
  color: $gray-600;
  font-size: 1.725em;
}

.article-date {
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
  color: $gray-700;
  font-weight: 500;
  margin-bottom: 2em;
  padding: .5em 0;
  text-align: right;
}




// カテゴリー
span[class^="category-label"] {
  background: transparent;
  color: inherit;
  font-size: 10px;
  display: inline-block;
  font-weight: 500;
  line-height: 1;
  padding: 5px 10px;
  white-space: nowrap;
  @include media(lg){
    font-size: 12px;
    padding: 6px 10px;
  }
  & + & {
    margin-left: 4px;
  }
}

// カテゴリーのラベルのカラー
// 1〜5は固定

span[class^="category-label-1"] { // 重要
  background: #f2188c;
  color: #fff;
}

span[class^="category-label-2"] { // お知らせ
  background: #007fff;
  color: #fff;
}

span[class^="category-label-3"] { // 外来担当医表
  background: #f97800;
  color: #fff;
}

span[class^="category-label-4"] { // 公開講座
  background: #59b201;
  color: #fff;
}

span[class^="category-label-5"] { // 採用情報
  background: #c41012;
  color: #fff;
}

span[class^="category-label-6"] {
  background: #6413C6;
  color: #fff;
}

span[class^="category-label-7"] {
  background: #2E8B57;
  color: #fff;
}

span[class^="category-label-8"] {
  background: #C41111;
  color: #fff;
}

span[class^="category-label-9"] {
  background: #B2AF10;
  color: #fff;
}
