@charset 'UTF-8';

// header
//
header {
  background: transparent;
  padding-bottom: map-get( $header_padding_bottom_array, sm );
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: map-get( $z_index_array, header );
  @include media(md) {
    padding-bottom: map-get( $header_padding_bottom_array, md );
  }
  @include media(lg) {
    padding-bottom: map-get( $header_padding_bottom_array, lg );
  }
}
// PCのトップページは fixed なし
@include media(lg){
  .home {
    header {
      position: absolute;
      top: 0;
      left: 0;
      &.fixed {
        position: fixed;
        transform: translateY(-100%);
      }
      &.visible {
        animation: menuVisible .3s;
        animation-fill-mode: forwards;
      }
    }
  }
}



// header-wrapper
//
.header-wrapper {
  background: #eceff1;
  position: relative;
  padding: 0 map-get( $header_container_padding_array, sm );
  @include media(md) {
    padding: 0 map-get( $header_container_padding_array, md );
  }
  @include media(lg) {
    background: $background_header;
    box-shadow: 0 1rem 2rem -2rem rgba( 0, 0, 0, .4 );
    height: map-get( $header_height_array, lg );
    padding: 0 map-get( $header_container_padding_array, lg );
  }
  @include width_wrapper( $header_container_min_width_array, $header_container_padding_array );
}



// header-container
//
.header-container {
  margin: 0 auto;
  @include width_container( $header_container_width_array );
}



// header-inner
//
.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: map-get( $header_height_array, sm );
  @include media(md) {
    height: map-get( $header_height_array, md );
  }
  @include media(lg) {
    padding: 12px 0;
    height: auto;
  }
}



// header-inner-left
// header-inner に内包して使用する
//
.header-inner-left {
  flex: auto;
}



// header-inner-right
// header-inner に内包して使用する
//
.header-inner-right {
  flex: auto;
}

.header-sub-mobile-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include media(lg){
    display: none;
  }
}

.header-sub-desktop-wrapper {
  display: none;
  @include media(lg){
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

ul.header-sub-nav {
  display: flex;
  align-items: center;
  line-height: 1;
  li {
    a {
      border-left: 1px solid $body_text_color;
      color: $body_text_color;
      display: inline-block;
      font-size: 14px;
      padding: 0 16px;
      text-decoration: none;
      transition: color $transition;
      &:hover {
        color: $base-color;
        text-decoration: underline;
      }
    }
    &:last-child {
      a {
        border-right: 1px solid $body_text_color;
      }
    }
  }
}

.header-sub-tel-info {
  margin-left: 40px;
  img {
    width: 18rem;
    height: auto;
  }
}



// header-title
//
.header-title {
  img {
    width: 14rem;
    height: auto;
    @include media(lg){
      width: 17.6rem;
    }
  }
}
