@charset 'UTF-8';

// variables
$current_class_name: '.current';
$current_view_class_name: '.current-view';



.tab-wrapper {
  width: 100%;
}



ul.tab-label {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -.3em;
  li {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    padding: 0 .3em;
    position: relative;

    .tab-label-item {
      background: $gray-200;
      border-radius: 6px 6px 0 0;
      cursor: pointer;
      color: $gray-600;
      display: block;
      font-size: 13px;
      padding: .75em 0;
      text-align: center;
      transition: background $transition;
      &:hover {
        background: $gray-300;
        color: $gray-800;
      }
      &#{$current_class_name} {
        background: $accent-color;
        color: $white-color;
        font-weight: 700;
      }
    }
  }
}



.tab-contents {
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;

  .tab-contents-item {
    display: none;
    padding: 1em;

    &#{$current_view_class_name} {
      display: block;
      animation: show .4s linear 0s;
    }
  }
}
