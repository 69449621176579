@charset 'UTF-8';

//
//  サイトマップ
//

$sitemap-list-gutter: 12px;



ul.sitemap-list {
  display: flex;
  flex-flow: row wrap;
  line-height: 2.4;
  margin: -$sitemap-list-gutter;

  > li {
    flex: 0 0 100%;
    padding: $sitemap-list-gutter;
    @include media(lg){
      flex: 0 0 25%;
    }

    a {
      color: $body-text-color;
      &:hover {
        color: $base-color;
      }
    }

    // 大カテゴリー
    .sitemap-category {
      border-bottom: 1px solid $gray-200;
      display: block;
      font-weight: 700;
      position: relative;
      text-decoration: none;
      &:before {
        font-family: $icon_font_family;
        content: '\f138';
        color: $base-color;
        display: inline-block;
        font-weight: 900;
        margin-right: .4em;
        text-align: center;
      }
    }
  }
}

ul.sitemap-list-child {
  margin-top: .5em;
  padding-left: 1.4em;

  li {
    a {
      display: inline-block;
      font-size: .875em;
      position: relative;
      text-decoration: none;
      &:before {
        font-family: $icon_font_family;
        content: '\f054';
        color: $base-color;
        display: inline-block;
        font-weight: 900;
        margin-right: .4em;
        text-align: center;
      }
      &.kango {
        &:after {
          font-family: $icon_font_family;
          content: '\f35d';
          color: $base-color;
          display: inline-block;
          font-size: .9em;
          font-weight: 900;
          margin-left: .4em;
          text-align: center;
        }
      }
    }
  }
}
