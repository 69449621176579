@charset 'UTF-8';

// icon
// Font Awesome
//
$icon_font_family: 'Font Awesome 5 Free';



// 種類を追加したい場合：'使用する名前': Fontawesomeのコード;
// Fontawesome のアイコンが Solid の場合 font-weight: 900; 、Regular の場合 font-weight: 400;
//
$icon-type-array: (
  'angle'       : '\f105',
  'default'     : '\f054', // arrows
  'back'        : '\f100',
  'check'       : '\f00c',
  'schedule'    : '\f0f0',
  'closure'     : '\f073',
  'section'     : '\f0f1',
  'access'      : '\f238',
  'fax'         : '\f1ac',
  'link'        : '\f0c1',
  'mail'        : '\f0e0',
  'new'         : '\f067',
  'pdf'         : '\f1c1',
  'plus'        : '\f055',
  'privacy'     : '\f13e',
  'send'        : '\f1d8',
  'tel'         : '\f879',
  'trash'       : '\f2ed',
  'update'      : '\f2f1',
  'outside'      : '\f35d'
) !default;
