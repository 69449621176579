@charset 'UTF-8';

// 症状別受診ガイド
//



.symptom-container {
  display: block;
  @include media(lg){
    display: flex;
  }
}

.symptom-container-item {
  display: block;
  @include media(lg){
    flex: 0 0 50%;
  }
}

.symptom-heading {
  color: $base-color;
  font-size: 1.25em;
  text-align: center;
  padding: 1em 0;
}

// 人物画像
.select-body-wrapper {
  margin: 0 auto;
  position: relative;
  width: 160px;
}

// ボタン
.select-body-btn {
  background: $base-color;
  border-radius: .25em;
  cursor: pointer;
  color: $white-color;
  display: inline-block;
  font-size: 14px;
  padding: .25em .75em;
  &.right {
    &::after {
      background: rgba($base-color,1);
      content: '';
      display: inline-block;
      width: 22px;
      height: 1px;
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
    }
  }
  &.left {
    &::after {
      background: rgba($base-color,1);
      content: '';
      display: inline-block;
      width: 22px;
      height: 1px;
      position: absolute;
      top: 50%;
      left: -20px;
      transform: translateY(-50%);
    }
  }
}

// 全身・その他
.select-body-lower {
  display: flex;
  align-items: center;
  margin: 1em auto 0;
  width: 240px;
}

// adult
.adult-tobu {
  position: absolute;
  top: 15px;
  right: -10px;
}
.adult-ganmen {
  position: absolute;
  top: 25px;
  left: -5px;
}
.adult-keibu {
  position: absolute;
  top: 65px;
  left: 5px;
}
.adult-kyobu {
  position: absolute;
  top: 100px;
  right: -10px;
}
.adult-fukubu {
  position: absolute;
  top: 190px;
  left: -15px;
}
.adult-koshi {
  position: absolute;
  top: 180px;
  right: -40px;
}
.adult-kafukubu {
  position: absolute;
  top: 230px;
  left: -30px;
}
.adult-joshi {
  position: absolute;
  top: 300px;
  right: -16px;
}
.adult-kashi {
  position: absolute;
  top: 340px;
  left: -15px;
}
.adult-all,
.adult-other,
.child-all,
.child-other {
  background: $accent-color;
  display: block;
  flex: 0 0 50%;
  margin: 0 .25em;
  padding: .5em 0;
  text-align: center;
}

// child
.child-tobu {
  position: absolute;
  top: 15px;
  right: -10px;
}
.child-ganmen {
  position: absolute;
  top: 25px;
  left: -5px;
}
.child-keibu {
  position: absolute;
  top: 65px;
  left: 5px;
}
.child-kyobu {
  position: absolute;
  top: 100px;
  right: -10px;
}
.child-fukubu {
  position: absolute;
  top: 190px;
  left: -15px;
}
.child-koshi {
  position: absolute;
  top: 180px;
  right: -40px;
}
.child-kafukubu {
  position: absolute;
  top: 230px;
  left: -30px;
}



// 結果表示域
//
.symptom-result-wrapper {
  animation: show $transition;
}

.symptom-result-heading {
  font-weight: 700;
  margin-bottom: 16px;
  padding-bottom: 8px;
  position: relative;
  text-align: center;
  &::after {
    background: $schedule-color;
    content: '';
    display: inline-block;
    width: 40px;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.symptom-result-container {
  padding-bottom: 20px;
  & + & {
    border-top: 1px solid $gray-300;
  }
}

.symptom-result-body {
  padding: 16px 0;
}

.symptom-result-lower-button {
  & + & {
    margin-top: 8px;
  }
}
