@charset 'UTF-8';

.alert {
  background: multi-array( $alert_color_array, 'base', 'bg' );
  border: $alert_border solid transparent;
  border-radius: $alert_border_radius;
  //box-shadow: $alert_box_shadow;
  color: multi-array( $alert_color_array, 'base', 'color' );
  line-height: map-get( $p_line_height_array, sm );
  padding: 1.5em;
  @include media(md) {
    line-height: map-get( $p_line_height_array, md );
  }
  @include media(lg) {
    line-height: map-get( $p_line_height_array, lg );
  }

  // Color Pattern theme color
  @each $name, $value in $alert_color_array {
    &.#{$name} {
      background: map-get( $value, 'bg' );
      //box-shadow: $alert_box_shadow;
      color: map-get( $value, 'color' );
    }
  }
  // Color Pattern gray & common color
  @each $name, $value in $basic_colors_array {
    &.#{$name} {
      background: $value;
      //box-shadow: $alert_box_shadow;
      @if ( $name == gray-900 or $name == gray-800 or $name == gray-700 or $name == gray-600 or $name == gray-500 or $name == 'black' or $name == 'red' or $name == 'blue' or $name == 'pink' ){
        color: $white-color;
      } @else {
        color: inherit;
      }
    }
  }
}

// Outline
// -------

.alert.outline {
  background: multi-array( $alert_outline_color_array, 'base', 'bg' );
  border: $alert_border solid multi-array( $alert_outline_color_array, 'base', 'border' );
  color: multi-array( $alert_outline_color_array, 'base', 'color' );
  text-shadow: none;

  // Color Pattern theme color
  @each $name, $value in $alert_outline_color_array {
    &.#{$name} {
      background: map-get( $value, 'bg' );
      border: $alert_border solid map-get( $value, 'border' );
      color: map-get( $value, 'color' );
    }
  }
  // Color Pattern gray & common color
  @each $name, $value in $basic_colors_array {
    &.#{$name} {
      background: $white-color;
      border: $alert_border solid $value;
      color: inherit;
    }
  }
}
