@charset 'UTF-8';

// variable
//
$pagetop_active_class_name: '.active';



.pagetop {
  background: rgba( $base-color, .8 );
  border-radius: 6px;
  color: $white-color;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: .4rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  opacity: 0;
  text-align: center;
  text-decoration: none;
  visibility: hidden;
  transition: background $transition;
  width: 44px;
  height: 44px;
  z-index: 99;
  &:after {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border-right: 3px solid #fff;
    border-top: 3px solid #fff;
    transform: rotate(-45deg);
    margin-top: 14px;
    transition: transform .3s ease-out;
    @include media(lg) {
      width: 20px;
      height: 20px;
      margin-top: 16px;
    }
  }
  &:hover {
    background: rgba( darken( $base-color, 10% ), .9);
  }

  &#{$pagetop_active_class_name} {
    display: block;
    opacity: 1;
    visibility: visible;
  }

  @include media(lg) {
    bottom: 6rem;
    right: 3rem;
    width: 52px;
    height: 52px;
  }
}
