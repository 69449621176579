@charset 'UTF-8';

// 単位を em に変換
// @used breakpoints
//
@function convert_em( $width )
{
  @return '#{ $width / 16 }em';
}




// grid
//
@function grid_margin( $key, $i: null )
{
  @if( $i != null ) {
    @return strip-unit( map-get( $grid_gutter_array, $key )) / 2 / $i / 10 + rem;
  } @else {
    @return strip-unit( map-get( $grid_gutter_array, $key )) / 2 / 10 + rem;
  }
}



// 単位を外して数値のみを返す
//
@function strip-unit( $number ) {
  @if type-of( $number ) == 'number' and not unitless( $number ) {
    @return $number / ( $number * 0 + 1 );
  }
  @return $number;
}



// 二次元配列
//
@function multi_array( $array, $key, $prop )
{
  $index: map-get( $array, $key );
  @return map-get( $index, $prop );
}



// キーのリスト、値のリストの 2つから新しい map を生成して返す
//
@function map_zip( $keys, $values )
{
  $length: length( $keys );
  $map: ();

  @for $i from 1 through $length {
    $tmp-keys: nth( $keys, $i );
    $tmp-values: nth( $values, $i );
    $tmp-map: ( $tmp-keys : $tmp-values );
    $map: map-merge( $map, $tmp-map );
  }
  @return $map;
}
