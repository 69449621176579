@charset 'UTF-8';

// flex
//
.flex-row {
  display: flex;
  flex-flow: row wrap;
}

// 縦組み
.flex-column {
  display: flex;
  flex-flow: column wrap;
}
