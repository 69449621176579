@charset 'UTF-8';

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;

  th, td {
    border: 1px solid $table_border_color;
    font-weight: normal;
    padding: .6em 1em;
    vertical-align: middle;
  }

  thead {
    th {
      background: $table_th_background_color;
      font-size: 12px;
      padding: .4em 1em;
    }
  }

  tbody {
    th {
      background: #f9fafb;
    }
  }

  td {
    background: $table_td_background_color;
    overflow-wrap : break-word;

  }

  &.table-fixed {
    @include media(md){
      table-layout: fixed;
    }
  }
}

// No border
.no-border {
  th, td {
    border: none;
    font-weight: normal;
    padding: .4em 1em;
    vertical-align: middle;
  }
  th {
    border: 2px solid $white-color;
  }
}



// th block
//
.th-block {
  border-bottom: 1px solid $table_border_color;
  @include media(md) {
    border: none;
  }
  th, td {
    display: block;
    @include media(md) {
      display: table-cell;
    }
  }
  th {
    border-bottom: none;
    width: 100%;
    @include media(md) {
      border-bottom: 1px solid $table_border_color;
      width: 15%;
    }
  }
  td {
    border-bottom: none;
    @include media(md) {
      border-top: 1px solid $table_border_color;
      border-bottom: 1px solid $table_border_color;
    }
  }
  &.th-nowrap th{
    @include media(md) {
    white-space: nowrap;
    }
  }
}

// スクロールを出すためのwrapper
//
.table-scroll-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include media(md) {
    white-space: normal;
  }
}



// 外来担当医表
table.table-schedule {
  margin-bottom: 1em;
  td {
    font-size: .875em;
    text-align: center;
    div + div {
      margin-top: .7em;
    }
  }
}
// 〜から実施
.table-schedule-exec-date {
  font-size: .9em;
  font-weight: 700;
  margin-bottom: .2em;
}



// 休診代診
table.table-closure {
  td {
    text-align: center;
  }
}