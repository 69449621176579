@charset 'UTF-8';

// Local
// 休診代診
// ---------- --------- --------- --------- --------- ---------

// アンカーリンクボタン
ul.closure-anchor-list-button {
  display: flex;
  flex-flow: row wrap;
  li {
    margin: 8px 8px 0 0;
  }
}

// 診療科ごとの出力
.closure-section-container {
  margin-top: 44px;
}

ul.closure-item-list {
  display: flex;
  flex-flow: row wrap;

  li {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    padding: 8px;
    @include media(lg){
      flex: 0 0 percentage( 1/3 );
      width: percentage( 1/3 );
      max-width: percentage( 1/3 );
    }
  }
}

.closure-item-container {
  background: $white-color;
  border: 1px solid $gray-300;
  border-radius: 6px;
  padding: 12px 20px;
}

.closure-title {
  border-radius: 6px;
  color: $white-color;
  display: block;
  font-size: 16px;
  font-weight: 700;
  padding: 6px 0;
  text-align: center;
  &._closure {
    background: $schedule-color;
  }
  &._change {
    background: $accent-color;
  }
  &._assist {
    background: $access-color;
  }
}

.closure-date {
  color: $gray-800;
  font-size: 17px;
  font-weight: 700;
  padding: 10px 0;
  text-align: center;
}

.closure-name {
  background: $gray-200;
  border-radius: 4px;
  color: $gray-800;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
  padding: 4px;
  text-align: center;
}

.closure-doctor,
.closure-comment {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
}



// 外来担当医表一覧ページ

.schedule-under-closure {

  li {
    display: block;
    &:nth-of-type(n+2){
      margin-top: 12px;
    }
    @include media(md){
      display: flex;
      align-items: center;
    }

    .suc-heading {
      display: flex;
      align-items: center;
    }

    .suc-title {
      border-radius: 4px;
      color: $white-color;
      display: inline-block;
      font-weight: 500;
      line-height: 1;
      margin-right: 12px;
      padding: 4px 8px;
      text-align: center;

      &._closure {
        background: $schedule-color;
      }
      &._change {
        background: $accent-color;
      }
      &._assist {
        background: $access-color;
      }
    }

    .suc-date {
      display: inline-block;
      line-height: 1;
      @include media(md){
        margin-right: 12px;
      }
    }

    .suc-doctor {
      @include media(md){
        margin-right: 12px;
      }
    }

    //お知らせはありません
    .closure-none {
      color: $gray-700;
      font-size: .925em;
    }
  }
}
