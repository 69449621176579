@charset 'UTF-8';

/**
*    Original CSS@frameworks
*
*    @since 2020.10.19
*/



// 関数
@import 'lib/functions';

// 変数
@import 'variables/variables';

// Mixin
@import 'lib/mixin';

// 初期設定
@import 'lib/initialize';

// レイアウト
@import 'lib/layout';

// grid
@import 'lib/grid';

// flex
@import 'lib/flex';

// header
@import 'lib/header';

// footer
@import 'lib/footer';

// copyright
@import 'lib/copyright';

// typography
@import 'lib/typography';

// icon
@import 'lib/icon';

// button
@import 'lib/button';

// burger
@import 'lib/burger';

// navigation
@import 'lib/navigation';

// breadlist
@import 'lib/breadlist';

// label
@import 'lib/label';

// table
@import 'lib/table';

// form
@import 'lib/form';

// list
@import 'lib/list';

// alert
@import 'lib/alert';

// block
@import 'lib/block';

// accordion
@import 'lib/accordion';

// tab
@import 'lib/tab';

// pagination
@import 'lib/pagination';

// pagetop
@import 'lib/pagetop';

// utilities
@import 'lib/utilities';

// adjoin
@import 'lib/adjoin';

// Top
@import 'top';
// 病院からのお知らせ
@import 'information';
// pages
@import 'pages';
// topix article
@import 'topix-article';
// footer mega menu
@import 'footer-mega-menu';
// local-sidebar
@import 'local-sidebar';
// 症状別受診ガイド
@import 'symptom';
// サイトマップ
@import 'sitemap';
// 休診代診
@import 'closure';
// ページ内リンク
@import 'page-anchor';

// fontawesome v5.12.1 使用ファイル 'variables/_var_icon.scss', '_function.scss', '_icon.scss', '_button.scss', '_list.scss'
@import 'fonts/fontawesome/fontawesome';

// 約物半角（〈〉《》「」『』【】〔〕（）［］｛｝）yakuhanjp にすると約物全部（、。！？〈〉《》「」『』【】〔〕・（）：；［］｛｝
@import 'fonts/yakuhanjp/yakuhanjp_s';
