@charset 'UTF-8';

// Local
// 下層ページ
// ---------- --------- --------- --------- --------- ---------

// hero
.local-hero-wrapper {
  margin-bottom: 44px;
  position: relative;
  width: 100%;
  height: 28vw;
  @include media(md){
    margin-bottom: 68px;
    height: 12vw;
  }
  @include media(lg){
    margin-bottom: 94px;
    height: 12vw;
  }
}

.local-hero-images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.local-hero-catch {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 0 map-get( $container_padding_array, sm );
  @include media(md) {
    padding: 0 map-get( $container_padding_array, md );
  }
  @include media(lg) {
    padding: 0 map-get( $container_padding_array, lg );
  }
  width: 100%;
}

.local-hero-catch-inner {
  margin: 0 auto;
  @include width_container( $container_width_array );
}



// 外来案内「初診の方へ」チャート
.initial-consultation-flow {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media(lg){
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  li {
    &.item {
      background: #f6fafc;
      border: $button_border solid $body-text-color;
      border-radius: 8px;
      padding: 2px;
      width: 100%;
      @include media(lg){
        writing-mode: vertical-rl;
        width: auto;
        min-height: 278px;
        // （ ）を横書き
        .to-sideways {
          text-orientation: sideways;
        }
      }
    }

    &.icon {
      @include media(lg){
        margin: auto .5em;
      }
      img {
        display: block;
        transform: rotate(90deg);
        min-width: 24px;
        height: auto;
        @include media(lg){
          transform: translateY(-120%) rotate(0deg);
        }
      }
    }

    span:not(.to-sideways) {
      border: 1px solid $body-text-color;
      border-radius: 4px;
      display: block;
      padding: calc(.8em + 1px) 1em .8em;
      text-align: center;
      @include media(lg){
        letter-spacing: .4em;
        padding: calc(1.5em + 1px) 1.5em .8em;
        text-align: left;
      }
    }
  }
}


dl.initial-consultation-word {
  dt {
    border-bottom: 1px solid $gray-200;
    color: $gray-700;
    margin-bottom: .5em;
    padding-bottom: .5em;
  }
  dd {
    font-size: .875em;
    margin-bottom: 3em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}



// 個人情報の利用目的
//

.personal-container {
  margin-bottom: 2em;
}

.personal-heading {
  font-size: 1.125em;
  font-weight: 700;
  margin-bottom: .875em;
}



// Access

.access-google-map {
  margin-bottom: 1.2em;
  width: 100%;
  height: 280px;
  iframe {
    width: 100%;
    height: 100%;
  }
  @include media(lg){
    height: 560px;
  }
}



// ローカルページナビボタン

ul.local-page-nav-btn {
  display: flex;
  flex-flow: column wrap;
  margin: -10px -8px;
  @include media(md){
    align-items: flex-end;
    flex-flow: row wrap;
    margin: -16px -10px;
  }

  li {
    padding: 10px 8px;
    @include media(md){
      flex: 0 0 percentage(1/3);
      padding: 16px 10px;
    }
    a {
      border-bottom: 1px solid $body-text-color;
      color: $body-text-color;
      display: block;
      line-height: 1.25;
      padding: 8px 28px 12px 12px;
      position: relative;
      text-decoration: none;
      transition: color $transition;
      @include media(md){
        padding: 12px 32px 12px 12px;
      }
      &::after {
        content: map-get( $icon_type_array, 'default' );
        color: inherit;
        display: inline-block;
        font-family: $icon_font_family;
        font-size: 14px;
        font-weight: 900;
        text-align: center;
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        width: 14px;
      }
      &:hover {
        color: $base-color;
      }
      // 新規ウインドウ
      &.outside {
        &::after {
          content: map-get( $icon_type_array, 'outside' );
        }
      }
    }
  }
}



// 外来担当医表のポップアップ
.popup-modal-wrapper {
  display: none;
  position: fixed;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 80vh;
  z-index: 10;
  &.visible {
    display: block;
    animation: show $transition;
  }
  @include media(md){
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 70vh;
  }
}

.popup-modal-container {
  background: #fff;
  box-shadow: 0 0 6rem -2rem rgba( 0, 0, 0, .4 );
  padding: 1em;
  position: relative;
  margin: 0 auto;
  width: 92%;
  @include media(md){
    padding: 1.725em;
    width: 681px;
  }
}

.popup-contents {
  height: 100%;
}

.popup-close {
  filter: drop-shadow( 0 0 2px rgba( 0, 0, 0, .4 ));
  position: absolute;
  top: -40px;
  right: 0;
  cursor: pointer;
  img {
    width: 32px;
    height: 32px;
  }
}

// ポップアップ内のコンテンツ
.doctor-introduction-wrapper {
  position: relative;
  max-height: calc( 80vh - 2em );
  overflow: auto;
  @include media(md){
    max-height: calc( 70vh - 3.45em );
  }
}

.doctor-name {
  border-bottom: 1px solid $gray-400;
  color: $gray-800;
  font-weight: 700;
  margin-bottom: .5em;
}

.doctor-introduction-container {
  font-size: .875em;
  & + & {
    margin-top: 1em;
  }
}

.doctor-item-title {
  margin-bottom: .2em;
}

.doctor-item-notes {
  font-size: .725em;
  margin-top: 1em;
}



// 全科外来担当医表
// アンカーリンク
ul.all-schedule-anchor-list-button {
  display: flex;
  flex-flow: row wrap;
  margin: -2px;

  li {
    flex: 0 0  percentage( 1/2 );
    padding: 2px;
    @include media(md){
      flex: 0 0  percentage( 1/3 );
    }
  }

  .page-anchor {
    display: block;
    font-size: .875em;
  }
}



// Youtube 埋め込み用
.movie {
  position: relative;
  width: 100%;
  &:before {
    content: '';
    display: block;
    padding-top: 56.25%; // 高さと幅の比を16:9に固定。9/16*100=56.25
  }
}

.movie_inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// 病棟紹介動画
video {
	width: 100%;
}

// 回復期リハ＿アイコン拡大
.icon_8rem {
	font-size: 8rem;
}



// IE11 判定
.ie-alert-wrapper {
  background: transparent;
  padding: 0 1em;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  transition: transform .5s;
  &.invisible {
    transform: translateY(100%);
  }
}

.ie-alert-container {
  background: #fff;
  border-top: 4px solid red;
  box-shadow: 0px 0px 20px -6px #b6b6b6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 2em 1em;
  @include media(md){
    padding: 2em;
    max-width: 768px;
  }
  @include media(lg){
    max-width: 1164px;
  }
}

.ie-alert-icon {
  flex: 0 0 52px;
  margin-right: 1em;
  @include media(md){
    flex: 0 0 64px;
  }
}

.ie-alert-heading {
  color: inherit;
  font-size: 1em;
  font-weight: 700;
  span {
    display: block;
    font-size: .875em;
    font-weight: 500;
    margin-top: .5em;
  }
}

.ie-alert-close {
  cursor: pointer;
  color: $gray-600;
  font-size: 1.5em;
  margin-left: 1em;
  &:hover {
    color: $gray-800;
  }
}
