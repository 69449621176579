@charset 'UTF-8';

// breadlist-wrapper
//
.breadlist-wrapper {
  background: $background_breadlist;
  padding: 20px map-get( $breadlist_container_padding_array, sm );
  @include media(md) {
    padding: 20px map-get( $breadlist_container_padding_array, md );
  }
  @include media(lg) {
    padding: 20px map-get( $breadlist_container_padding_array, lg );
  }
  @include width_wrapper( $breadlist_container_min_width_array, $breadlist_container_padding_array );
}



// breadlist-container
//
.breadlist-container {
  margin: 0 auto;
  @include width_container( $breadlist_container_width_array );
}



// breadlist
//
ol.breadlist {
  display: flex;
  align-items: center;
  font-size: .725em;

  li {
    color: inherit;
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;

    & + li::before {
      content: '＞';
      color: $gray-500;
      padding-right: .5em;
      padding-left: .5em;
    }

    a {
      color: $link_color;
      text-decoration: none;
      &::active,
      &::visited {
        color: inherit;
      }
      &:hover {
        color: $link_color;
        text-decoration: underline;
      }
    }

    &.current {
      color: $gray-700;
    }
  }
}
