@charset 'UTF-8';



.page-anchor {
  border: 1px solid lighten( $base-color, 20% );
  border-radius: 6px;
  color: $base-color;
  display: inline-block;
  font-size: $link-btn-font-size;
  font-weight: 500;
  line-height: 1.42875 !important;
  padding: 10px $link-btn-font-size;
  text-decoration: none;
  transition: background $transition, border $transition;
  &:before {
    content: '\f078';
    display: inline-block;
    font-family: $icon-font-family;
    font-weight: 900;
    margin-right: 8px;
    transition: transform $transition;
  }
  &:hover {
    //background: lighten( $base-color, 25% );
    border: 1px solid $base-color;
    &:before {
      transform: translateY(.2em);
    }
  }

  // Color Pattern
  @each $name, $value in $theme_colors_array {
    &.#{$name} {
      border: 1px solid lighten( $value, 20% );
      color: $value;
      &:hover {
        border: 1px solid $value;
      }
    }
  }
}
