@charset 'UTF-8';

// grid
//
.grid {
  display: flex;
  flex-flow: row wrap;
  margin: ( - grid_margin(sm) );
  @include media(md) {
    margin: ( - grid_margin(md) );
  }
  @include media(lg) {
    margin: ( - grid_margin(lg) );
  }
}



// col
//
.col {
  padding: grid_margin(sm);
  flex: 1 1 0;
  @include media(md) {
    padding: grid_margin(md);
    width: auto;
  }
  @include media(lg) {
    padding: grid_margin(lg);
  }
  & figure {
    text-align: center;
  }
}



// grid items
//
@for $i from 1 through $grid_columns {
  .sm-#{$i} {
    @include make_grid( 100% / ( $grid_columns / $i ) );
  }
  .sm-#{$i}-center {
    @include make_grid( 100% / ( $grid_columns / $i ));
    margin: 0 auto;
  }
  .sm-offset-#{$i} {
    @include make_grid( 100% / ( $grid_columns / $i ));
    margin-left: ( 100% / ( $grid_columns / $i ) );
  }
}

@include media(md) {
  @for $i from 1 through $grid_columns {
    .md-#{$i} {
      @include make_grid( 100% / ( $grid_columns / $i ) );
    }
    .md-#{$i}-center {
      @include make_grid( 100% / ( $grid_columns / $i ) );
      margin: 0 auto;
    }
    .md-offset-#{$i} {
      @include make_grid( 100% / ( $grid_columns / $i ));
      margin-left: ( 100% / ( $grid_columns / $i ) );
    }
  }
}

@include media(lg) {
  @for $i from 1 through $grid_columns {
    .lg-#{$i} {
      @include make_grid( 100% / ( $grid_columns / $i ) );
    }
    .lg-#{$i}-center {
      @include make_grid( 100% / ( $grid_columns / $i ) );
      margin: 0 auto;
    }
    .lg-offset-#{$i} {
      @include make_grid( 100% / ( $grid_columns / $i ));
      margin-left: ( 100% / ( $grid_columns / $i ) );
    }
  }
}



// grid tile
//
.grid-tile {
  display: flex;
  flex-flow: row wrap;
  margin: ( - grid_margin(sm) );
  @include media(md) {
    margin: ( - grid_margin(md) );
  }
  @include media(lg) {
    margin: (- grid_margin(lg));
  }
  // flex items
  @for $i from 1 through $grid_columns {
    .sm-col-#{$i} {
      @include make_grid( 100% / $i );
      padding: grid_margin(sm);
    }
  }
  @include media(md) {
    @for $i from 1 through $grid_columns {
      .md-col-#{$i} {
        @include make_grid( 100% / $i );
        padding: grid_margin(md);
      }
    }
  }
  @include media(lg) {
    @for $i from 1 through $grid-columns {
      .lg-col-#{$i} {
        @include make_grid( 100% / $i );
        padding: grid_margin(lg);
      }
    }
  }
}



// .input-grid
//
.input-grid {
  display: flex;
  flex-flow: row wrap;
  margin: ( - (map-get( $input_grid_gutter_array, sm ) / 2) );
  @include media(md) {
    margin: ( - (map-get( $input_grid_gutter_array, md ) / 2) );
  }
  @include media(lg) {
    margin: ( - (map-get( $input_grid_gutter_array, lg ) / 2) );
  }
  .col {
    padding: map-get( $input_grid_gutter_array, sm ) / 2;
    @include media(md) {
      padding: map-get( $input_grid_gutter_array, md ) / 2;
    }
    @include media(lg) {
      padding: map-get( $input_grid_gutter_array, lg ) / 2;
    }
  }
}



// adjoin 隣接
//
.grid + .grid,
.grid-tile + .grid-tile,
.grid + .grid-tile,
.grid-tile + .grid {
  margin-top: grid_margin(sm);
  @include media(md){
    margin-top: grid_margin(md);
  }
  @include media(lg){
    margin-top: grid_margin(lg);
  }
}



// 揃える
//
.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}
// 縦組み  旧Android iOS6以前は未対応
.justify-end-vertical {
  align-content: flex-end;
}

.justify-center-vertical {
  align-content: center;
}

.justify-between-vertical {
  align-content: space-between;
}

.justify-around-vertical {
  align-content: space-around;
}



// 子要素の天地の揃え
// align-items は親要素 align-self は子要素
//
.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-self-end {
  align-self: flex-end;
}

.align-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}
