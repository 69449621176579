@charset 'UTF-8';

// .form-field
// 項目同士の余白
$form_field_margin_array: (
  sm: 2em,
  md: 2em,
  lg: 2em
) !default;



// f-grid gutter
//
$input_grid_gutter_array: (
  sm : 4px,
  md : 4px,
  lg : 4px
) !default;
