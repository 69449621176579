@charset 'UTF-8';

// border の太さ
//
$alert-border: 1px;



// border-radius
//
$alert-border-radius: 8px;



// box-shadow
//
$alert-box-shadow: 0 2px 4px 0 rgba( 0,0,0, .12 );



// background-color, color
//
$alert-color-array: (
  'base' : ( 'bg': $base-color, 'color': $white-color ),
  'accent'   : ( 'bg': $accent-color,     'color': $white-color ),
  'gray'    : ( 'bg': $gray-600,      'color': $white-color ),
  'schedule': ( 'bg': $schedule-color,  'color': $white-color ),
  'closure' : ( 'bg': $closure-color,   'color': $white-color ),
  'section' : ( 'bg': $section-color,   'color': $white-color ),
  'access'  : ( 'bg': $access-color,    'color': $white-color )
) !default;



// outline
// background-color, color, border-color
//
$alert-outline-color-array: (
  'base'    : ( 'bg': $white-color, 'color': $body-text-color,      'border': $base-color ),
  'accent'   : ( 'bg': $white-color, 'color': $body-text-color,     'border': $accent-color ),
  'gray' : ( 'bg': $white-color, 'color': $body-text-color, 'border': $gray-600 ),
  'schedule': ( 'bg': $white-color, 'color': $body-text-color,  'border': $schedule-color ),
  'closure' : ( 'bg': $white-color, 'color': $body-text-color,   'border': $closure-color ),
  'section' : ( 'bg': $white-color, 'color': $body-text-color,   'border': $section-color),
  'access'  : ( 'bg': $white-color, 'color': $body-text-color,    'border': $access-color )
) !default;
