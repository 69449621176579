/*! Yaku Han JP (Gothic - Small Amount) v3.0.0 SIL by Qrac / Based on Noto Sans CJK JP */

@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 100;
  src: url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Thin.eot");
  src: url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Thin.woff2") format("woff2"),
  url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Thin.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 200;
  src: url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Light.eot");
  src: url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Light.woff2") format("woff2"),
  url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Light.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 300;
  src: url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-DemiLight.eot");
  src: url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-DemiLight.woff2") format("woff2"),
  url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-DemiLight.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 400;
  src: url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Regular.eot");
  src: url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Regular.woff2") format("woff2"),
  url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Regular.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 500;
  src: url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Medium.eot");
  src: url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Medium.woff2") format("woff2"),
  url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Medium.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 700;
  src: url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Bold.eot");
  src: url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Bold.woff2") format("woff2"),
  url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Bold.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 900;
  src: url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Black.eot");
  src: url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Black.woff2") format("woff2"),
  url("#{$font_directory_path}/YakuHanJPs/YakuHanJPs-Black.woff") format("woff");
}
