@charset 'UTF-8';

// footer
//
footer {
  background: $common-bg-color;
  flex: none;
  width: 100%;
  z-index: map-get( $z_index_array, footer );
}



// footer-wrapper
//
.footer-wrapper {
  background: $white-color;
  position: relative;
  padding: 16px map-get( $footer_container_padding_array, sm );
  @include media(md) {
    padding: 16px map-get( $footer_container_padding_array, md );
  }
  @include media(lg) {
    padding: 40px map-get( $footer_container_padding_array, lg );
  }
  @include width_wrapper( $footer_container_min_width_array, $footer_container_padding_array );
}



// footer-container
//
.footer-container {
  margin: 0 auto;
  @include width_container( $footer_container_width_array );
}



// footer-inner
//
.footer-inner {
  display: flex;
  font-size: .8em;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
}



// footer-inner-left
// footer-inner に内包して使用する
//
.footer-inner-left {
  flex: 0 0 100%;
  width: 100%;
  @include media(md){
    flex: 0 0 50%;
    width: 50%;
  }
}



// footer-global-wrapper
//
.footer-global-wrapper {
  display: flex;
  flex-direction: column;
  .footer-mega-menu-wrapper {
    order: 0;
  }
  .footer-hospital-infomation {
    order: 1;
  }
  @include media(lg){
    .footer-mega-menu-wrapper {
      order: 1;
    }
    .footer-hospital-infomation {
      margin-top: 0;
      margin-bottom: 36px;
      order: 0;
    }
  }
}



// footer-principle-wrapper
//
.footer-principle-wrapper {
  display: flex;
  justify-content: center;
  padding: 42px map-get( $footer_container_padding_array, 'sm' );
  @include media(md) {
    padding: 42px map-get( $footer_container_padding_array, 'md' );
  }
  @include media(lg) {
    padding: 56px map-get( $footer_container_padding_array, 'lg' );
  }
  img {
    width: 286px;
    height: auto;
    @include media(lg){
      width: 476px;
    }
  }
}



// footer-mobile-information
//
.footer-mobile-information {
  margin-bottom: 24px;
  padding: 0 map-get( $footer_container_padding_array, 'sm' );
  @include media(md) {
    padding: 0 map-get( $footer_container_padding_array, 'md' );
  }
  @include media(lg) {
    display: none;
  }
}

.footer-mobile-container {
  .hospital {
    margin-bottom: 12px;
    img {
      width: 160px;
    }
  }
}

ul.hospital-information {
  line-height: 1.6;
}



// footer-nav-wrapper
//
.footer-nav-wrapper {
  padding: 0 map-get( $footer_container_padding_array, 'sm' );
  @include media(md) {
    display: flex;
    justify-content: center;
    padding: 0 map-get( $footer_container_padding_array, 'md' );
  }
  @include media(lg) {
    padding: 0 map-get( $footer_container_padding_array, 'lg' );
  }
  // ナビ
  ul.footer-nav {
    display: flex;
    justify-content: center;
    width: 100%;
    li {
      position: relative;
      + li {
        margin-left: 8px;
        @include media(lg){
          margin-left: 0;
        }
      }
      @include media(lg){
        padding: 0;
        border-left: 1px solid $body-text-color;
        &:last-child {
          border-right: 1px solid $body-text-color;
        }
      }
      a {
        background: transparent;
        color: $body-text-color;
        display: block;
        font-size: 12px;
        line-height: 1;
        padding: 0;
        text-decoration: none;
        transition: color $transition;
        &:before {
          content: '＞';
          display: inline-block;
        }
        &:hover {
          color: $base-color;
          text-decoration: underline;
        }
        @include media(lg){
          background: transparent;
          font-size: 14px;
          line-height: 1;
          padding: 0 16px;
          &:before {
            content: none;
          }
        }
      }
    }
  }
}



// footer-common-button-wrapper
//
ul.footer-common-button-wrapper {
  display: none;
  .home & {
    display: none;
  }
  @include media(lg){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  li {
    flex: 0 0 percentage(1/4);
    border-left: 1px solid $gray-500;
    &:last-child {
      border-right: 1px solid $gray-500;
    }

    a {
      color: $body-text-color;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 0;
      text-decoration: none;
      transition: color $transition;
      &:before {
        display: inline-block;
        font-family: $icon_font_family;
        font-size: 36px;
        font-weight: 900;
        margin-right: 16px;
      }
      &:hover {
        color: $base-color;
      }
      &.schedule {
        &:before {
          content: map-get( $icon_type_array, 'schedule' );
          color: $schedule-color;
        }
      }
      &.closure {
        &:before {
          content: map-get( $icon_type_array, 'closure' );
          color: $closure-color;
        }
      }
      &.section {
        &:before {
          content: map-get( $icon_type_array, 'section' );
          color: $section-color;
        }
      }
      &.access {
        &:before {
          content: map-get( $icon_type_array, 'access' );
          color: $access-color;
        }
      }
    }
  }
}

// footer hospital infomation
//
.footer-hospital-infomation {
  background: #edf0f2;
  border: $button_border solid $body-text-color;
  display: none;
  margin-top: 36px;
  padding: 2px;
  width: 100%;
  .home & {
    display: none;
  }
  @include media(lg){
    display: block;
  }
}

.footer-hospital-infomation-inner {
  border: 1px solid $body-text-color;
  padding: 36px 16px;
  height: 100%;
  overflow: hidden;
  @include media(lg){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
  }
}

.infomation-inner-item {
  & + & {
    margin-top: 36px;
    @include media(lg){
      margin-top: 0;
    }
  }
  @include media(lg){
    flex: 1;
    &._hospital {
      flex: 0 0 auto;
      margin-right: 80px;
      width: 280px;
    }
  }
}

.hospital-container {
  text-align: center;
  @include media(lg){
    text-align: left;
  }
  .hospital {
    margin-bottom: 20px;
    img {
      width: 174px;
      height: auto;
    }
    @include media(lg){
      margin-bottom: 40px;
    }
  }
  .contact {
    text-align: center;
    @include media(lg){
      text-align: right;
    }
    img {
      width: 172px;
      height: 32px;
    }
    a.js-tel-link {
      border-bottom: 1px solid $base-color;
    }
    .address {
      font-size: 14px;
      margin-bottom: 12px;
      @include media(lg){
        //text-align: left;
      }
    }
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  @include media(lg){
    flex-direction: row;
  }
}

.table-container-inner {
  flex: 1;
  & + & {
    margin-top: 36px;
    @include media(lg){
      margin-top: 0;
      margin-left: 20px;
    }
  }
}

table.footer-information {
  caption {
    margin-bottom: 12px;
    text-align: left;
  }
  th, td {
    text-align: center;
    vertical-align: middle;
  }
  th {
    background: #f9fafa;
    font-size: 12px;
  }
}
